import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import LoginAnimation from "../login/loginanimation.component.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./signup.component.css";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
// import GoogleLogin from "react-google-login";
import GoogleLoginPopup from "../login/googleloginpopup.component.jsx";
import { gapi } from "gapi-script";
import MuiPhoneInput from "material-ui-phone-number";
import { TextField, IconButton, MenuItem, InputLabel } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwtDecode from "jwt-decode";
import Navbar from "../header/header.component.jsx";

function Signup() {
  const clientId =
    "28183007093-58tqp32c9rsjtnvsa19rp0h27b0sh2r3.apps.googleusercontent.com";
  const [passwordType, setPasswordType] = useState("password");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [emailfirstname, setEmailFirstName] = useState("");
  const [emailLastname, setEmailLastName] = useState("");
  const [googleemail, setGoogleEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCountryCode, setCountryCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [characterLength, setCharacterLength] = useState("none");
  const [validPassword, setValidPassword] = useState("none");
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [signmessage, setSignMessage] = useState("");
  const [defaultRegion, setDefaultRegion] = useState("");
  const [alert, setAlert] = useState("none");
  const [openGLPopup, setOpenGLPopup] = useState(false);
  const [resendbuttondisabled, setresendbuttondisabled] = useState(false);
  const [resendmessage, setresendmessage] = useState("");
  const [googleenabled, setGoogleEnabled] = useState(false);
  const [termsUrl, setTermsUrl] = useState('#');
  const [googleloginbuttondisable, setgoogleloginbuttondisable] =
    useState(false);

  const [termscondition, settermscondition] = useState({
    emailsend: false,
    privacypolicy: false,
  });
  const [validationer, setvalidationer] = useState(false);

  const updateTermsLink = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      if (!response.ok) {
        throw new Error('Failed to fetch location data');
      }

      const locationData = await response.json();
      const country = locationData?.country;

      // Set the correct URL based on the country
      const updatedTermsUrl =
        country === 'IN'
          ? 'https://home.tag8.in/policies/terms-of-service'
          : 'https://home.tag8.co/policies/terms-of-service';

      setTermsUrl(updatedTermsUrl);
    } catch (error) {
      console.error('Error fetching location data:', error.message);

      // Fallback to the default `.in` domain
      setTermsUrl('https://home.tag8.in/policies/terms-of-service');
    }
  };
  useEffect(() => {
    const storedFirstName = localStorage.getItem("first_name") || "";
    const storedLastName = localStorage.getItem("last_name") || "";
    const storedEmail = localStorage.getItem("email") || "";
    // console.log(storedEmail, "email");
    setEmailFirstName(storedFirstName);
    setEmailLastName(storedLastName);
    setGoogleEmail(storedEmail);
    if (storedEmail) {
      setGoogleEnabled(true);
    }
    updateTermsLink();
  }, []);

  const fetchUserCountry = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();

      if (data && data.country_code) {
        const userCountryCode = data.country_code.toLowerCase() || "in";

        setDefaultRegion(userCountryCode);
      } else {
       
        setDefaultRegion("in");
      }
    } catch (error) {
      setDefaultRegion("in");

      return
    }
  };
  useEffect(() => {
    fetchUserCountry();
  }, []);

  // function to handel termsandcondition state
  const handelprivacy = (e) => {
    settermscondition({
      ...termscondition,
      [e.target.name]: !termscondition?.[e.target.name],
    });
  };
  // const [countryCode, setCountryCode] = useState("");

  // const handleCountryCodeChange = (e) => {
  //   console.log(e, "e");
  //   // setCountryCode(e.target.value);
  // };
  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

  const responseGoogle = (response, error) => {
    var decoded = jwtDecode(response.credential);

    if (response) {
      var newLine = "\r\n";
      var msg = "Please enable third party cookies if you want to login.";

      msg += newLine;
      msg += newLine;
      msg +=
        "Here are the instructions how to enable 3rd-party cookies in your Google Chrome browser:";
      msg += newLine;
      msg +=
        "1. In Google Chrome browser, at the top right, click More and then Settings.";
      msg += newLine;
      msg += "2. In the Privacy and Security section, click Site settings";
      msg += newLine;
      msg +=
        "3. Select Cookies and uncheck the box next to Block third-party cookies and site data:";

      if (
        response.details === "Cookies are not enabled in current environment."
      ) {
        window.alert(msg);

        setgoogleloginbuttondisable(true);
      } else {
        setgoogleloginbuttondisable(false);
        // setGoogleUser(decoded);
        // console.log(googleuser);
        // console.log(response, "response");
        const state = {
          first_name: capitalizeName(decoded.given_name)
            ? capitalizeName(decoded.given_name)
            : capitalizeName(decoded.name),
          last_name: capitalizeName(decoded.family_name)
            ? capitalizeName(decoded.family_name)
            : null,
          email: decoded.email,
        };
        // const email = state.email;

        axios
          .post("/api/userverify", { email: state.email })
          .then((res) => {
            if (res.data.data === "User Already Exists") {
              axios
                .post("/api/signupwithgoogle", state)
                .then((res) => {
                  let data = res;

                  var url = document.URL.split("/");

                  if (data.data.uid) {
                    switch (url[2]) {
                      case "localhost:3000":
                        // console.log("local", res.data.token);
                        document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
                        break;
                      case "dashboard.tag8.co.in":
                        // console.log("testing", res.data.token);
                        document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
                        break;
                      case "dashboard.tag8.in":
                        // console.log("live", res.data.token);
                        document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
                        break;
                      default:
                        break;
                    }
                    localStorage.setItem("mid", data.data._id);
                    localStorage.setItem("uid", data.data.uid);
                    localStorage.setItem("name", data.data.first_name);
                    localStorage.setItem("isAdmin", data.data.isAdmin);
                    localStorage.setItem("logintype", "google");

                    window.location.href =
                      "/dashboard/" + localStorage.getItem("uid");
                    // console.log("bcccccccccc");
                    localStorage.removeItem("first_name");
                    localStorage.removeItem("email");
                    localStorage.removeItem("last_name");
                  } else {
                    switch (url[2]) {
                      case "localhost:3000":
                        // console.log("local", res.data.token);
                        document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
                        break;
                      case "dashboard.tag8.co.in":
                        // console.log("testing", res.data.token);
                        document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
                        break;
                      case "dashboard.tag8.in":
                        // console.log("live", res.data.token);
                        document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
                        break;
                      default:
                        break;
                    }
                    setOpenGLPopup(true);

                    localStorage.setItem("logintype", "google");

                    axios
                      .get("/api/getUserProfile", {
                        headers: {
                          Authorization: res.data.token,
                          "Content-Type": "application/json",
                        },
                      })
                      .then((res) => {
                        localStorage.setItem("uid", res.data.uid);
                        window.location.href =
                          "/dashboard/" + localStorage.getItem("uid");
                      })
                      .catch((error) => {
                        console.error(error.message);
                      });
                  }
                })
                .catch((error) => {
                  // console.log(error.response.data.error);
                  setSignMessage(error.response.data.error);
                });
              // window.location.href =
              //   "/dashboard/" + localStorage.getItem("uid");

              // setMessage("User Already Exists.");
              // setAlert("flex");
            } else {
              // console.log(state.first_name);
              setEmailFirstName(state.first_name);
              setEmailLastName(state.last_name);
              setGoogleEmail(state.email);
              setGoogleEnabled(true);
            }
          })
          .catch((error) => {
            // console.log(error.message);
          });
      }
    } else {
      console.error(error.message);
    }
  };
  function resendOTP(event) {
    event.preventDefault();
    setresendbuttondisabled(true);
    const state = {
      email: email ? email : googleemail,
    };
    axios
      .post("/api/resend", state)
      .then((res) => {
        setMessage(
          "Email verification code has been sent on your email id. If email is not received, please check spam folder or click on resend button."
        );
        setAlert("flex");
      })
      .catch((error) => {
        console.error(error.message);
      });
    setresendmessage("Resend mail in 30 seconds");
    setTimeout(() => {
      setresendbuttondisabled(false);
      setresendmessage("");
    }, 30000);
  }
  function verifyOTP(event) {
    event.preventDefault();
    const state = {
      email: email ? email : googleemail,
      otp: otp,
    };
    axios
      .post("/api/Verifyemail", state)
      .then((res) => {
        if (res.data === "otp is incorrect") {
          setMessage("OTP is Incorrect.");
          setAlert("flex");
        } else {
          setMessage("OTP Verified Successfully");
          setAlert("flex");
          window.location.href = "/#/login";
          handleClose();
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
  function handleSubmit(event) {
    event.preventDefault();
   
    const state = {
      _id: "",
      uid: "",
      first_name: firstName ? firstName : emailfirstname,
      last_name: lastName ? lastName : emailLastname,
      phone: phone,
      phonecountrycode: phoneCountryCode,
      email: email ? email : googleemail,
      password: password,
      tag_number: "",
      tag_status: "",
      emailconsent: termscondition?.emailsend,
      privacypolicyconsent: termscondition?.privacypolicy,
    };
    console.log(
      phoneCountryCode,
      email,
      firstName,
      lastName,
      phone,
      phoneCountryCode
    );
    if (
      !(email || googleemail) ||
      !(firstName || emailfirstname) ||
      !(lastName || emailLastname) ||
      !phone ||
      !phoneCountryCode
    ) {
      setMessage("Please fill in all required fields.");
      return;
    }

    if (googleenabled) {
      axios
        .post("/api/signupwithgoogle", state)
        .then((res) => {
          let data = res;

          var url = document.URL.split("/");

          if (data.data.uid) {
            switch (url[2]) {
              case "localhost:3000":
                // console.log("local", res.data.token);
                document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
                break;
              case "dashboard.tag8.co.in":
                // console.log("testing", res.data.token);
                document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
                break;
              case "dashboard.tag8.in":
                // console.log("live", res.data.token);
                document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
                break;
              default:
                break;
            }

            localStorage.setItem("uid", data.data.uid);
            localStorage.setItem("name", data.data.first_name);
            localStorage.setItem("isAdmin", data.data.isAdmin);
            localStorage.setItem("logintype", "google");

            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          } else {
            switch (url[2]) {
              case "localhost:3000":
                // console.log("local", res.data.token);
                document.cookie = `token=${res.data.token}; domain=localhost; path=/; samesite=none; secure;`;
                break;
              case "dashboard.tag8.co.in":
                // console.log("testing", res.data.token);
                document.cookie = `token=${res.data.token}; domain=.tag8.co.in; path=/; samesite=none; secure;`;
                break;
              case "dashboard.tag8.in":
                // console.log("live", res.data.token);
                document.cookie = `token=${res.data.token}; domain=.tag8.in; path=/; samesite=none; secure;`;
                break;
              default:
                break;
            }
            setOpenGLPopup(true);

            localStorage.setItem("logintype", "google");

            axios
              .get("/api/getUserProfile", {
                headers: {
                  Authorization: res.data.token,
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                localStorage.setItem("uid", res.data.uid);
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              })
              .catch((error) => {
                console.error(error.message);
              });
          }
        })
        .catch((error) => {
          // console.log(error.message);
          setMessage(error.message);
        });
    } else {
      if (!password) {
        setMessage("Please enter a password.");
        return;
      }

      if (!termscondition?.emailsend || !termscondition?.privacypolicy) {
        setMessage("Please accept the checkbox to continue.");

        return;
      }
      axios
        .post("/api/userRegister", state)
        .then(({ data }) => {
          if (data?.status) {
            setMessage(
              "Email verification code has been sent on your email id. If email is not received, please check spam folder or click on resend button."
            );
            handleShow();
          } else {
            setMessage(data?.message || "registartion failed");
            setAlert("flex");
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage("registartion failed");
          setAlert("flex");
        });
    }

    // console.log(googleenabled, "enaba");

    // if (googleenabled) {
    //   console.log(googleenabled, "enabaled");

    // }
  }

  // function formValid() {
  //   return (
  //     emailValid &&
  //     phoneValid &&
  //     passwordValid &&
  //     fNValid &&
  //     lNValid &&
  //     pCCValid
  //   );
  // }
  // useEffect(() => {
  //   setFNValid(firstName ? true : false);
  //   setLNValid(lastName ? true : false);
  //   setPhoneValid(phone ? true : false);
  //   setPCCValid(phoneCountryCode ? true : false);
  //   if (re.test(email)) {
  //     setEmailValid(true);
  //   } else {
  //     setEmailValid(false);
  //   }
  //   if (password.length > 7 || password === "") {
  //     setCharacterLength("none");
  //     if (passwordRE.test(password)) {
  //       console.log(passwordRE, "password ahe");
  //       setPasswordValid(true);
  //       setValidPassword("none");
  //     } else if (password !== "") {
  //       console.log(passwordRE, "password nahi ahe");
  //       setPasswordValid(false);
  //       setValidPassword("block");
  //     } else {
  //       console.log(passwordRE, "password nahi ahe second");
  //       setPasswordValid(false);
  //       setValidPassword("none");
  //     }
  //   } else {
  //     setValidPassword("block");
  //     setCharacterLength("block");
  //     setPasswordValid(false);
  //   }
  //   console.log(formValid, "valid");
  //   console.log(googleenabled, "google");
  //   setSubmitDisabled(!formValid() || !googleenabled);
  // }, [googleenabled]);

  // const handleCountryCodeChange = (value) => {
  //   console.log(value, "valuecountry");
  //   setCountryCode(value);
  // };
  const handlePhoneNumberChange = (value, details) => {
    let { dialCode } = details;

    if (dialCode && value) {
      const phCode = "+" + dialCode;
      const sanitizedValue = value.replace(/[^0-9]/g, "");
      console.log(sanitizedValue);
      const phoneNumber = sanitizedValue.replace(
        phCode.replace(/[^0-9]/g, ""),
        ""
      );
      setCountryCode(phCode);
      // const phoneNumber = value.replace(phCode, "");

      // console.log(phoneNumber)
      setPhone(phoneNumber);
    }
  };

  useEffect(() => {
    if (alert === "flex") {
      setTimeout(() => setAlert("none"), 8000);
    }

    localStorage.setItem("loginemail", email);
    const initClient = () => {
      //  gapi.client.init({
      gapi.auth2.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const handlePasswordChange = (e) => {
    const txt = e.target.value;
    setPassword(txt);

    if (txt.length >= 8) {
      valid("islen");
    } else {
      invalid("islen");
    }

    if (txt.match(/[A-Z]/) != null) {
      valid("capital");
    } else {
      invalid("capital");
    }

    if (txt.match(/[a-z]/) != null) {
      valid("small");
    } else {
      invalid("small");
    }

    if (txt.match(/[0-9]/) != null) {
      valid("isnum");
    } else {
      invalid("isnum");
    }
  };

  // const togglePasswordVisibility = () => {
  //   setPasswordType((prevType) =>
  //     prevType === "password" ? "text" : "password"
  //   );
  // };
  const togglePasswordVisibility = () => {
    setPasswordType((prevShowPassword) => !prevShowPassword);
  };
  // const valid = (id) => {
  //   // Handle the validation logic for each rule
  // };

  // const invalid = (id) => {
  //   // Handle the invalidation logic for each rule
  // };
  //If Form Valid
  // console.log(phoneCountryCode, "country");
  // console.log(phone, "phone");

  const valid = (item) => {
    let password = document.querySelector(`#${item}`);
    password.style.display = "none";
  };
  //If Form Not Valid
  const invalid = (item) => {
    let password = document.querySelector(`#${item}`);
    password.style.display = "block";
  };
  return (
    <>
      <Navbar />
      <div className="signup__background">
        <div className="signup__container">
          <form className="signup__form" onSubmit={handleSubmit}>
            <h3
              style={{
                textAlign: "center",
                fontFamily: "Prompt",
                // fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              Sign Up
            </h3>
            <div className="login__google">
              <GoogleLogin
                onSuccess={responseGoogle}
                onError={responseGoogle}
                context="signup"
                type={"standard"}
                theme={"filled_black"}
                size={"large"}
                // width={"10rem"}
                shape={"rectangular"}
                text={"signup_with"}
              />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  fontFamily: "prompt",
                  letterSpacing: "0.5px",
                  color: "Red",
                }}
              >
                {signmessage}
              </p>
            </div>
            <div className="divider-container-signup">
              <hr className="divider" />
              <div className="divider-text">OR</div>
            </div>
            <h3
              style={{
                textAlign: "center",
                fontFamily: "Prompt",
                // fontWeight: "bold",
                fontSize: "1.5rem",
                // marginTop: "2rem",
              }}
            >
              Create New Account.
            </h3>
            <div className="Signupform-textfield">
              <TextField
                type="text"
                label="First Name"
                value={firstName ? firstName : emailfirstname}
                onChange={(e) => setFirstName(e.target.value)}
                required
                size="medium"
                InputProps={{
                  endAdornment: (
                    <IconButton edge="end" aria-label="personal-details-icon">
                      <AssignmentIndIcon />
                    </IconButton>
                  ),
                }}
                // style={{ width: "250px", fontSize: "16px" }}
              />
              <TextField
                type="text"
                label="Last Name"
                value={lastName ? lastName : emailLastname}
                onChange={(e) => setLastName(e.target.value)}
                required
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    // backgroundColor: "#FFF",
                    paddingLeft: 4,
                    paddingRight: 4,
                    color: "#383838",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton edge="end" aria-label="personal-details-icon">
                      <AssignmentIndIcon />
                    </IconButton>
                  ),
                }}
              />
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "1.5em" }}
            >
              {defaultRegion && (
                <MuiPhoneInput
                  defaultCountry={defaultRegion}
                  value={`+${phoneCountryCode} ${phone}`}
                  regions={[
                    "asia",
                    "middle-east",
                    "america",
                    "caribbean",
                    "oceania",
                    "europe",
                    "africa",
                  ]}
                  variant="outlined"
                  onChange={handlePhoneNumberChange}
                />
              )}
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1.5em" }}
            >
              <TextField
                type="email"
                placeholder="Email ID"
                // className="pl-3"
                label="Email ID"
                value={email ? email : googleemail}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                required
                InputLabelProps={{
                  style: {
                    fontSize: 14,
                    // backgroundColor: "#FFF",
                    paddingLeft: 4,
                    paddingRight: 4,
                    color: "#383838",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton edge="end" aria-label="personal-details-icon">
                      <ContactMailIcon />
                    </IconButton>
                  ),
                }}
              />
              {!googleemail && (
                <TextField
                  type={passwordType ? "text" : "password"}
                  placeholder="Password"
                  label="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  disabled={googleemail}
                  required
                  InputLabelProps={{
                    style: {
                      fontSize: 14,
                      paddingLeft: 4,
                      paddingRight: 4,
                      color: "#383838",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        aria-label="toggle-password-visibility"
                      >
                        {passwordType ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                fontFamily: "prompt",
                letterSpacing: "0.5px",
                color: "Red",
                paddingLeft: "10px",
              }}
            >
              <span style={{ display: characterLength }} id="islen">
                Password must be atleat 8 characters
              </span>
              <span style={{ display: validPassword }} id="issp">
                Password must contain -{" "}
              </span>
              <span style={{ display: validPassword }} id="isnum">
                Atleast one number
              </span>
              <span style={{ display: validPassword }} id="capital">
                Atleast one uppercase letter
              </span>
              <span style={{ display: validPassword }} id="small">
                Atleast one lowercase letter
              </span>
            </div>
            {/* <div className="checkbox-css" style={{ marginTop: "-2em" }}>
              <input
                type="checkbox"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  // marginTop: "-11px",
                  // marginRight: "10px",
                  // marginLeft: "10px",
                }}
                onClick={() =>
                  setPasswordType(
                    passwordType === "password" ? "text" : "password"
                  )
                }
              ></input>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  // paddingTop: "5px",
                  margin: "0.5rem 0rem 0.5rem 0rem",
                }}
              >
                Show Password
              </p>
            </div> */}
            <div className="checkbox-css" style={{ marginTop: "-1em" }}>
              <input
                type="checkbox"
                name="emailsend"
                onChange={handelprivacy}
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0rem 0rem 1rem 0rem",
                }}
                required
                checked={termscondition?.emailsend ? true : false}
              ></input>
              <p
                className="termsandcondition"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  fontFamily: "Prompt",
                  // paddingTop: "1px",
                }}
              >
                Yes Please,I would like to receive communication by email
              </p>
            </div>
            <div className="checkbox-css" style={{ marginTop: "-1em" }}>
              <input
                type="checkbox"
                name="privacypolicy"
                onChange={handelprivacy}
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0rem 0rem 1rem 0rem",
                }}
                required
                checked={termscondition?.privacypolicy ? true : false}
              ></input>
              <p
                className="termsandcondition"
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  fontFamily: "Prompt",
                  // paddingTop: "1px",
                }}
              >
                I have read and agreed with the{" "}
                <a
                  id="terms"
                  target="_blank"
                  rel="noreferrer"
                  href={termsUrl} 
                >
                  Terms and Conditions 
                </a>
              </p>
            </div>
            <button
              id="signup__button"
              onClick={handleSubmit}
              // disabled={submitDisabled}
            >
              Let's Get Started
            </button>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                fontFamily: "prompt",
                letterSpacing: "0.5px",
                color: "Red",
                paddingLeft: "10px",
              }}
            >
              {message}
            </p>
            {/* <Dialog
              fullWidth
              maxWidth="xs"
              open={show}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent className="verify-mail">
                <h1
                  style={{
                    margin: "20px",
                    font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                    letterSpacing: "normal",
                  }}
                >
                  EMAIL VERIFICATION
                </h1>
                <p className="text-danger ml-4">{message}</p>
                <div
                  className="mb-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    className="pl-3 input__field__dialogs"
                    style={{ margin: "20px", height: "35px" }}
                    value={email}
                  ></input>
                  <input
                    className="pl-3 input__field__dialogs"
                    style={{ margin: "20px", height: "35px" }}
                    placeholder=" OTP"
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                  ></input>
                  <button
                    className="verifyOtp-button"
                    style={{ margin: "10px 80px" }}
                    onClick={verifyOTP}
                  >
                    VERIFY
                  </button>
                  <button
                    className="otp-buttons"
                    style={{ margin: "10px 80px" }}
                    onClick={resendOTP}
                  >
                    Resend
                  </button>
                  <button
                    className="otp-buttons"
                    style={{
                      margin: "10px 80px",
                      marginBottom: "50px !important",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </DialogContent>
            </Dialog> */}
          </form>
        </div>
        <div className="AlertSignup" style={{ display: alert }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              fontFamily: "prompt",
              letterSpacing: "0.5px",
              color: "Red",
              paddingLeft: "10px",
            }}
          >
            {message}
          </p>
          <button onClick={() => setAlert("none")}>Cancel</button>
        </div>{" "}
      </div>
    </>
  );
}

export default Signup;
