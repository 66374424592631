import React, { useEffect, useState } from "react";
import Navbar from "../header/header.component";
import "./profile.component.css";
import * as BsIcons from "react-icons/bs";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Collapse, IconButton, List, ListItemText } from "@material-ui/core";
import { InputAdornment } from "@mui/material";
import { QrReader } from "react-qr-reader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CryptoJS from "crypto-js";
import { Grid, ListItemButton, Snackbar, Switch } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MuiPhoneInput from "material-ui-phone-number";
import Button from "@mui/material/Button";
import ModeIcon from "@mui/icons-material/Mode";
import Dialog from "@mui/material/Dialog";
import * as FcIcons from "react-icons/fc";
import * as AiIcons from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Sidebar from "../sidebar/sidebar.component.jsx";
import DeleteIcon from "@mui/icons-material/Delete";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CircularProgress from "@mui/material/CircularProgress";
import { switchUrls } from "../../api/index.js";
const vehicle_frontned = switchUrls("vehicle_frontend");
function Newpro() {
  const [loading, setloading] = useState(false);
  const [promotionalemail, setpromotionalemail] = useState(false);
  const [sidebar, setsidebar] = useState(true);
  const [activatetag, setActivateTag] = useState("");
  const [tagNumber, setTagNumber] = useState("");
  const [showQR, setshowQR] = useState(false);
  const [show, setShow] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [menu1, setmenu1] = useState(true);
  const [menu2, setmenu2] = useState(true);
  const [menu3, setmenu3] = useState(true);
  const [menu4, setmenu4] = useState(true);
  const [detailsmodal, setdetailsmodal] = useState(false);
  const [emailmodal, setemailmodal] = useState(false);
  const [userdata, setuserdata] = useState({});
  const [editableuserdata, seteditable] = useState({});
  const [otpmodal, setoptmodal] = useState(false);
  const [otpvalue, setoptvalue] = useState("");
  const [emailtochange, setemailtochange] = useState({});
  const [sucess, setsucess] = useState(false);
  const [error, seterror] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [deletemodal, setdeletemodal] = useState(false);
  const [allowdelete, setallowdelete] = useState(false);
  const [passworddata, setpassworddata] = useState({});
  const [passworderror, setpassworderror] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [tagNotFound, setTagNotFound] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const [emailsetings, setemailsetings] = useState(false);
  const [deletepassword, setdeletepassword] = useState(false);
  const [deletedata, setdeletedata] = useState({});
  const [deleteotp, setdeleteotp] = useState(false);
  const [counter, setcounter] = useState(false);
  const [deleteproceed, setdeleteproceed] = useState(false);
  const [tabvalue, settabvalue] = useState(0);
  const [personelinf, setpesonalinf] = useState({ menu1: true, menu2: true });
  const [humnainf, sethumaninf] = useState([]);
  const [petinf, setpetinf] = useState([]);
  const [loader, setloader] = useState(false);
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleNotFound = () => setTagNotFound(false);
  const handleActivated = () => setAlreadyActivated(false);
  const delete_cookie = (name) => {
    var url = document.URL.split("/");

    switch (url[2]) {
      case "localhost:3000":
        document.cookie =
          name +
          "=; Path=/; domain=localhost; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        break;
      case "dashboard.tag8.co.in":
        document.cookie =
          name +
          "=; Path=/; domain=.tag8.co.in; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        break;
      case "dashboard.tag8.in":
        document.cookie =
          name +
          "=; Path=/; domain=.tag8.in; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        break;
      default:
        break;
    }
  };
  const deleteaccountApi = async (e) => {
    try {
      e.preventDefault();
      const { data } = await axios.post(
        "/api/deleteaccount",
        { userdata, otpvalue, deletedata },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (data?.status) {
        setcounter(false);
        setoptvalue("");
        delete_cookie("token");
        window.location.reload();
        localStorage.clear();
      } else {
        setoptvalue("");
        setcounter(false);
        seterror(true);
        setalertmsg(data?.message || "unexpected error ocured");
      }
    } catch (error) {
      seterror(true);
      setoptvalue("");
      setcounter(false);
      setalertmsg("unexpected error ocured");
    }
  };

  const handeldeleterevoke = () => {
    setoptvalue("");
    setdeletedata("");
    setdeleteotp(false);
    setcounter(false);
  };
  const calculateRows = (address) => {
    const charactersPerRow = 50; // Adjust this based on your design
    return Math.ceil(address.length / charactersPerRow);
  };
  const verifydeleteOtp = async (e) => {
    try {
      e.preventDefault();
      setdeleteotp(false);
      setcounter(true);
    } catch (error) {
      // console.log(error);
    }
  };
   function CheckLfTag(tagNo) {
    axios
      .get(
        "/api/getLftagsInfoNumber/" + tagNo,
        {},
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        //console.log("res.data--->",res.data);
        // console.log("res.message--->", res);
        if (res.message === "Invalid Tag") {
          setShow(false);
          setTagNotFound(true);
        }
        if (res.data.length > 0) {
          //CheckLfTag(state.tag_number); /activate-lftag/
          // console.log("CALL THE API");
          window.location.href = "/#/activate-lftag/" + tagNumber;
        } else {
          setShow(false);
          setTagNotFound(true);
        }
      })
      .catch((err) => {
        // console.log(err);
        setShow(false);
        setTagNotFound(true);
      });
  }
    function activateTag() {
  const state = {
    tag_number: activatetag.toUpperCase(),
  };
  setloader(true);

  const loaderTimer = setTimeout(() => {
    setloader(false);
  }, 5000);

  axios
    .post("/api/checkTagNumberWithoutHeader", state, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const productcategory = res.data.tag_data.product_category;

      // Check for "Dolphin" or "Smart" categories
      if (
        productcategory.startsWith("Dolphin") || 
        productcategory.startsWith("Smart")
      ) {
        setWalletDialogOpen(true);
        return;
      }

      // Clear session storage
      sessionStorage.removeItem("tag_number");

      // Route based on product category
      const routes = {
        "QR PET": `/pet-activation/${activatetag}`,
        "QR Key": `/key-activation/${activatetag}`,
        "QR bag": `/bag-activation/${activatetag}`,
        "QR Bag": `/bag-activation/${activatetag}`,
        "E-Bag": `/bag-activation/${activatetag}`,
        "QR Medical": `/bag-activation/${activatetag}`,
        "QR Passport": `/passport-activation/${activatetag}`,
        "E-Passport": `/passport-activation/${activatetag}`,
        "QR Gadget": `/earphone-activation/${activatetag}`,
        "Generic": `/generic-activation/${activatetag}`,
        "QR Wallet": `/wallet-activation/${activatetag}`,
        "QR Human": `/human-activation/${activatetag}`,
        "QR HUMAN": `/human-activation/${activatetag}`,
        "QR Vehicle": `${vehicle_frontned}/#/vehicle-activation/${activatetag}`,
      };

      if (routes[productcategory]) {
        window.location.href = routes[productcategory];
      } else {
        console.warn("Unknown product category:", productcategory);
      }
    })
    .catch((error) => {
      clearTimeout(loaderTimer);
      setloader(false);

      const responseData = error.response?.data;

      if (responseData === "Tag not found by the specified number.") {
        console.log(responseData,"responseData")
        setTagNotFound(true);
        // setalertmsg("Tag not found!")
        CheckLfTag(state.tag_number);
      } else if (responseData === "Tag is already activated.") {
        // setShow(false);
        setAlreadyActivated(true);
        // setalertmsg("Tag is already activated.")
      } else {
        console.error("Unexpected error:", error.response || error);
      }
    });
  } 


  const handelpromortionalemail = async () => {
    try {
      const { data } = axios.post(
        "/api/changepromotionalemail",
        { userdata, promotionalemail },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setpromotionalemail(!promotionalemail);
    } catch (error) {
      // console.log(error);
    }
  };

  const handelemailsetings = async () => {
    try {
      setemailsetings(!emailsetings);
      const { data } = axios.post(
        "/api/changeemailsettings",
        { userdata, emailsetings },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const handeldeletechange = (e) => {
    setdeletedata({ ...deletedata, [e.target.name]: e.target.value });
  };

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  let usertoken = readCookie("token");
  const token = usertoken;

  const monitorpassword = (e) => {
    const cpass = e?.target?.value;
    setpassworddata({
      ...passworddata,
      newpassconfirm: cpass,
    });
    if (passworddata?.newpass === cpass) {
      setpassworderror({ ...passworderror, newpassconfirm: false });
    } else {
      setpassworderror({ ...passworderror, newpassconfirm: true });
    }
  };
  const handelpassword = (e) => {
    const txt = e.target.value;
    const name = e.target.name;

    setpassworddata({ ...passworddata, [name]: txt });
    // console.log(txt, name, "testttttttttt");
    if (name === "newpass") {
      if (txt.length < 6 && txt !== "") {
        setPasswordError("Minimum 6 characters required");
      } else if (txt.match(/[A-Z]/) === null) {
        setPasswordError("Password must contain at least one uppercase letter");
      } else if (txt.match(/[a-z]/) === null) {
        setPasswordError("Password must contain at least one lowercase letter");
      } else if (txt.match(/[0-9]/) === null) {
        setPasswordError("Password must contain at least one digit");
      } else {
        setPasswordError("");
      }
    }
  };
  const handleClickShowPassword = (field) => {
    if (field === "newpass") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "newpassconfirm") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const getProductinformation = async () => {
    try {
      const { data } = await axios.get("/api/getuserproductinformation", {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      // console.log(data, "dattttta");
      // console.log(data, "dattttta");
      if (data?.petDetails) {
        setpetinf(data?.petDetails);
      }
      if (data?.humanDetails) {
        sethumaninf(data?.humanDetails);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handelproduct = () => {
    settabvalue(1);
    getProductinformation();
  };

  const getUserdetails = async () => {
    try {
      const { data } = await axios.get("/api/getUserProfile", {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (data?.emailconsent !== undefined) {
        setemailsetings(data.emailconsent);
      } else {
        setemailsetings(false);
      }

      if (data?.promotional_emailconsent !== undefined) {
        setpromotionalemail(data?.promotional_emailconsent);
      } else {
        setpromotionalemail(false);
      }
      setuserdata(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const changepassword = async (userdata) => {
    try {
      if (!passworderror?.newpassconfirm) {
        const { data } = await axios.post(
          "/api/changepassword",
          { uid: userdata?.uid, passworddata },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        setpassworddata({ newpassconfirm: "", newpass: "" });
        if (data.status) {
          setalertmsg(data?.message);
          setsucess(true);
        } else {
          setalertmsg(data?.message || "unexpected error ocured");
          seterror(true);
        }
      }
    } catch (error) {}
  };

  const handeldeletemodal = async () => {
    setdeletemodal(true);
  };

  const handelotpverify = async (e) => {
    try {
      e.preventDefault();
      const { data } = await axios.post("/api/verifymailopt", {
        email: emailtochange?.email,
        otp: otpvalue,
        uid: emailtochange?.uid,
      });
      if (data?.status) {
        setoptmodal(false);
        setoptvalue("");
        setalertmsg(data?.message);
        setsucess(true);
        window.location.reload();
      } else {
        if (data?.message) {
          setoptmodal(false);
          setoptvalue("");
          setalertmsg(data?.message);
          seterror(true);
        } else {
          setoptmodal(false);
          setoptvalue("");
          setalertmsg("unexpected error occured");
          seterror(true);
        }
      }
    } catch (error) {
      // console.log(error);
      setoptmodal(false);
      setoptvalue("");
      setalertmsg("unexpected error occured");
      seterror(true);
    }
  };
  const renderQRModal = () => {
    return (
      <Dialog
        open={showQR}
        fullWidth
        maxWidth="xs"
        onClose={() => setshowQR(false)}
        aria-labelledby={"Scan QR"}
      >
        <div>
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setTagNumber(
                  result?.text.split("/")[result?.text.split("/").length - 1]
                );
                setshowQR(false);
              }

              if (error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </Dialog>
    );
  };
  const handelemailchange = async (e) => {
    try {
      e.preventDefault();
      const { data } = await axios.post("/api/changeemail", editableuserdata, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      // console.log(data);
      if (data.status) {
        setemailmodal(false);
        setoptmodal(true);
        setemailtochange(data?.userdata);
      } else {
        if (data?.message) {
          setemailmodal(false);
          setalertmsg(data?.message);
          seterror(true);
        } else {
          setalertmsg("unexpected error ocuured");
          seterror(true);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handeldetails = async (e) => {
    try {
      e.preventDefault();
      setdetailsmodal(false);

      if (
        !editableuserdata.first_name ||
        !editableuserdata.last_name ||
        !editableuserdata.phone
      ) {
        seterror(true);
        setalertmsg("Please fill in all required fields.");
        return;
      }
      // console.log(editableuserdata);
      const { data } = await axios.post("/api/updateUser", editableuserdata, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      seteditable({});
      // console.log(data);
      if (data.status) {
        setalertmsg(data?.message);
        setsucess(true);
        window.location.reload();
      } else {
        setalertmsg(data?.message || "unexpected error ocured");
        seterror(true);
      }
    } catch (error) {
      seterror(true);
      setalertmsg("unexpected error ocured");
      localStorage.clear();
      const delete_cookie = (name) => {
        var url = document.URL.split("/");

        switch (url[2]) {
          case "localhost:3000":
            document.cookie =
              name +
              "=; Path=/; domain=localhost; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            break;
          case "dashboard.tag8.co.in":
            document.cookie =
              name +
              "=; Path=/; domain=.tag8.co.in; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            break;
          case "dashboard.tag8.in":
            document.cookie =
              name +
              "=; Path=/; domain=.tag8.in; samesite=none; secure; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            break;
          default:
            break;
        }
      };
      delete_cookie("token");
      localStorage.clear();
      window.location.reload();
    }
  };

  const phonehandler = async (value, details) => {
    try {
      let { dialCode } = details;
      if (dialCode && value) {
        const phCode = "+" + dialCode;
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        // console.log(sanitizedValue);
        const phoneNumber = sanitizedValue.replace(
          phCode.replace(/[^0-9]/g, ""),
          ""
        );
        // console.log(phoneNumber);
        seteditable({
          ...editableuserdata,
          phone: phoneNumber,
          phonecountrycode: phCode,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const Emergencyphonehandler = async (value, details) => {
    try {
      let { dialCode } = details;
      if (dialCode && value) {
        const phCode = "+" + dialCode;
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        // console.log(sanitizedValue);
        const phoneNumber = sanitizedValue.replace(
          phCode.replace(/[^0-9]/g, ""),
          ""
        );
        // console.log(phoneNumber);
        seteditable({
          ...editableuserdata,
          emergencyphone: phoneNumber,
          emergencyphonecountrycode: phCode,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handelchange = (e) => {
    seteditable({ ...editableuserdata, [e.target.name]: e.target.value });
  };

  const handeldetailsmodal = (userdata) => {
    seteditable(userdata);
    setdetailsmodal(true);
  };

  const handelemailmodal = (usedata) => {
    seteditable(userdata);
    setemailmodal(true);
  };

  const sendotpfordelete = async (e) => {
    try {
      setloading(true);
      e.preventDefault();
      const { data } = await axios.post(
        "/api/deleteotp",
        { userdata, deletedata },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      //setdeletedata({ email: "", confirmpassword: "", reason : "" });
      // console.log(data);
      if (data?.status) {
        setloading(false);
        setdeletepassword(false);
        setdeleteotp(true);
        //setdeletedata({ email: "", confirmpassword: "", reason : "" })
      } else {
        setloading(false);
        setdeletepassword(false);
        seterror(true);
        setalertmsg(data?.message || "unexpected error occured");
        setdeletedata({ email: "", confirmpassword: "", reason: "" });
      }
    } catch (error) {
      setloading(false);
      setdeletepassword(false);
      seterror(true);
      setalertmsg("unexpected error occured");
      setdeletedata({ email: "", confirmpassword: "", reason: "" });
      // console.log(error);
    }
  };

  useEffect(() => {
    getUserdetails();
    getProductinformation();
  }, []);
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  function handleLostFound() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }

  function handleAllTags() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }

  function handleActiveInactive() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }
  const rows = userdata?.address ? calculateRows(userdata.address) : 1;
   const walletDialogHandleClose = () => {
    setWalletDialogOpen(false);
  };
  return (
    <>
      <Navbar />
      <div className="dashboard-main-wrapper">
        <div className="dashboard-left-wrapper">
          <Sidebar
            open={sidebarOpen}
            onClose={handleSidebarToggle}
            handleShow={handleShow}
            handleAllTags={handleAllTags}
            handleActiveInactive={handleActiveInactive}
            handleLostFound={handleLostFound}
            showNotifications={true}
          />
           <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => setshowQR(true)}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={activatetag}
                    onChange={(e) => {
                      const inputValue = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase()
                        .slice(0, 10);
                      setActivateTag(inputValue);
                    }}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="dashboard-verifyOtp-button"
                    onClick={activateTag}
                  >
                    ACTIVATE
                  </button>
                </div>
              </DialogContent>
            </Dialog>
             <Dialog
        PaperProps={{
              style: { borderRadius: 15, padding: '1.5em',position: 'relative' },
            }}
        open={walletDialogOpen}
        onClose={walletDialogHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <IconButton
                aria-label="close"
                onClick={walletDialogHandleClose}
                style={{
                  position: 'absolute !impotant' ,
                  top: '10px !impotant',
      right: '10px !impotant',
      zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  }><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  }>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions>
      </Dialog>
        </div>
        <div className="profile-right-wrapper">
          <div className="profile_main_div">
            <Tabs
              value={tabvalue}
              onChange={() => settabvalue(tabvalue)}
              variant="scrollable"
            >
              <Tab
                value={0}
                onClick={() => settabvalue(0)}
                label="Settings"
                wrapped
              />
              <Tab
                value={1}
                onClick={() => settabvalue(1)}
                label="Product Information"
                wrapped
              />
            </Tabs>
            {tabvalue === 0 ? (
              <>
                <form>
                  <List style={{ paddingTop: "50px" }}>
                    <ListItemButton onClick={() => setmenu1(!menu1)}>
                      <ListItemText>
                        <h5>Personal Information</h5>
                      </ListItemText>
                      {menu1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </List>
                  <Collapse in={menu1}>
                    <div className="personalinformationdiv">
                      <div className="personaldetails">
                        <div className="twodiv">
                          <div style={{ margin: "1rem" }}>
                            <TextField
                              disabled
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#666" },
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                              name="first_name"
                              value={userdata?.first_name}
                              label="First Name"
                              variant="outlined"
                            />
                          </div>
                          <div style={{ margin: "1rem" }}>
                            <TextField
                              disabled
                              label="Last Name"
                              variant="outlined"
                              value={userdata?.last_name}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#666" },
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                            />
                          </div>
                        </div>
                        {userdata?.address ? (
                          <>
                            <div
                              className="profile-textarea"
                              style={{ margin: "1rem" }}
                            >
                              <TextareaAutosize
                                disabled
                                aria-label="empty textarea"
                                placeholder="Address"
                                value={userdata?.address}
                                style={{
                                  width: "100%",
                                  padding: "0.5rem",
                                  borderColor: "#e4e4e4",
                                  borderRadius: "4px",
                                  color: "#000000",
                                  resize: "none",
                                }}
                                minRows={rows}
                                onFocus={(e) => e.target.blur()}
                              />
                            </div>
                          </>
                        ) : null}
                        <div style={{ margin: "1rem" }}>
                          <MuiPhoneInput
                            disabled
                            label="Contact"
                            defaultCountry="in"
                            value={`+${userdata?.phonecountrycode} ${userdata?.phone}`}
                            regions={[
                              "america",
                              "caribbean",
                              "asia",
                              "middle-east",
                              "oceania",
                              "europe",
                              "africa",
                            ]}
                            name="guardian_mobile"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                              style: { color: "#666" },
                            }}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  edge="end"
                                  aria-label="personal-details-icon"
                                ></IconButton>
                              ),
                              readOnly: true,
                            }}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                          />
                        </div>
                        {userdata?.emergencyphone &&
                        userdata?.emergencyphonecountrycode ? (
                          <div style={{ margin: "1rem" }}>
                            <MuiPhoneInput
                              disabled
                              label="Emergency Contact Number"
                              defaultCountry="in"
                              value={`+${userdata?.emergencyphonecountrycode} ${userdata?.emergencyphone}`}
                              regions={[
                                "america",
                                "caribbean",
                                "asia",
                                "middle-east",
                                "oceania",
                                "europe",
                                "africa",
                              ]}
                              name="guardian_mobile"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#666" },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    edge="end"
                                    aria-label="personal-details-icon"
                                  ></IconButton>
                                ),
                                readOnly: true,
                              }}
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                            />
                          </div>
                        ) : null}
                        <div style={{ margin: "1rem" }}>
                          <Button
                            onClick={() => handeldetailsmodal(userdata)}
                            variant="outlined"
                            startIcon={<ModeIcon />}
                            sx={{
                              "&:focus": {
                                border: "1px solid rgba(25, 118, 210, 0.5)",
                                color: "#1976d2",
                                // Adjust the color and style as needed
                              },
                            }}
                          >
                            Edit Details
                          </Button>
                        </div>
                        {userdata?.password ? (
                          <>
                            <div style={{ margin: "1rem" }}>
                              <p>Email Address</p>
                            </div>
                            <div
                              className="emailaddressection "
                              style={{
                                margin: "1rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ width: "90%" }}>
                                <TextField
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { color: "#666" },
                                  }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  sx={{
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "#000000",
                                    },
                                  }}
                                  value={userdata?.email || ""}
                                  label="Email Address"
                                  variant="outlined"
                                />
                              </div>

                              <div
                                style={{
                                  width: "10%",
                                  margin: "1rem",
                                  cursor: "pointer",
                                }}
                              >
                                <p onClick={() => handelemailmodal(userdata)}>
                                  <ModeIcon />
                                </p>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </Collapse>
                  {userdata?.password ? (
                    <>
                      <List>
                        <ListItemButton onClick={() => setmenu2(!menu2)}>
                          <ListItemText>
                            <h5>Change Password</h5>
                          </ListItemText>
                          {menu2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </List>
                      <Collapse in={menu2}>
                        <div className="personalinformationdiv">
                          <div className="pwdfields">
                            <div className="twodiv">
                              <div className="pwdinputbox">
                                <TextField
                                  type={showNewPassword ? "text" : "password"}
                                  name="newpass"
                                  onChange={handelpassword}
                                  value={passworddata?.newpass}
                                  label="New Password"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() =>
                                            handleClickShowPassword("newpass")
                                          }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showNewPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      style: {
                                        boxShadow: "none",
                                        height: "2em",
                                      },
                                    },
                                  }}
                                />
                                <div style={{ height: "20px" }}>
                                  {passwordError && (
                                    <label
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {passwordError}
                                    </label>
                                  )}
                                </div>
                              </div>
                              <div className="pwdinputbox">
                                <TextField
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  name="newpassconfirm"
                                  onChange={monitorpassword}
                                  value={passworddata?.newpassconfirm}
                                  label="Confirm New Password"
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() =>
                                            handleClickShowPassword(
                                              "newpassconfirm"
                                            )
                                          }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showConfirmPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      style: {
                                        boxShadow: "none",
                                        height: "2em",
                                      },
                                    },
                                  }}
                                />
                                <div style={{ height: "20px" }}>
                                  {passworderror?.newpassconfirm && (
                                    <label
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      Password not matching
                                    </label>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div style={{ margin: "1rem" }}>
                              <Button
                                onClick={() => changepassword(userdata)}
                                variant="outlined"
                                startIcon={<ModeIcon />}
                                sx={{
                                  "&:focus": {
                                    border: "1px solid rgba(25, 118, 210, 0.5)",
                                    color: "#1976d2",
                                    // Adjust the color and style as needed
                                  },
                                }}
                              >
                                Update Password
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Collapse>
                    </>
                  ) : null}
                  <List>
                    <ListItemButton onClick={() => setmenu3(!menu3)}>
                      <ListItemText>
                        <h5>Email Services</h5>
                      </ListItemText>
                      {menu3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </List>
                  <Collapse in={menu3}>
                    <div
                      className="emailnotification"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginLeft: "1rem" }}>
                        <p>Turn on to recieve Transactional Email from tag8</p>
                      </div>
                      <div>
                        <Switch
                          checked={emailsetings}
                          onChange={handelemailsetings}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                      <p style={{ fontSize: "12px" }}>
                        You will recieve e-mail notification when the tag is
                        activated, deactivated, scaned, and report lost . if you
                        turn of you will not get the email
                      </p>
                    </div>
                    <div
                      className="emailnotification"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginLeft: "1rem" }}>
                        <p>Turn on to recieve Promotional Email from tag8</p>
                      </div>
                      <div>
                        <Switch
                          checked={promotionalemail}
                          onChange={handelpromortionalemail}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                      <p style={{ fontSize: "12px" }}>
                        You will receive promotional email notifications from
                        tag8, which may include offers for new products and
                        newsletters.
                      </p>
                    </div>
                  </Collapse>
                  <List>
                    <ListItemButton onClick={() => setmenu4(!menu4)}>
                      <ListItemText>
                        <h5>Delete Account</h5>
                      </ListItemText>
                      {menu4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </List>
                  <Collapse in={menu4}>
                    <div className="deleteacount">
                      <div style={{ margin: "1rem" }}>
                        <p>Delete Your Account</p>
                        <p>All information will be losted</p>
                      </div>
                      <div style={{ marginLeft: "1rem" }}>
                        <Button
                          onClick={handeldeletemodal}
                          variant="outlined"
                          startIcon={<DeleteIcon />}
                          color="error"
                          sx={{
                            "&:focus": {
                              border: "1px solid rgba(211, 47, 47, 0.5)",
                              color: "#d32f2f",
                              // Adjust the color and style as needed
                            },
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </Collapse>
                </form>
              </>
            ) : tabvalue === 1 ? (
              <>
                <List>
                  <ListItemButton
                    onClick={() =>
                      setpesonalinf({
                        ...personelinf,
                        menu1: !personelinf?.menu1,
                      })
                    }
                  >
                    <ListItemText>
                      <h5>QR Human Tag Personal Information</h5>
                    </ListItemText>
                    {personelinf?.menu1 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </List>
                <Collapse in={personelinf?.menu1}>
                  {humnainf.some((e) => e != null) ? (
                    humnainf.map((e, index) => {
                      if (
                        e &&
                        (e.guardian_firstname ||
                          e.guardian_name ||
                          e.guardian_email ||
                          e.guardian_mobile)
                      ) {
                        return (
                          <>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Box
                                component="form"
                                sx={{
                                  "& .MuiTextField-root": {
                                    m: 1,
                                    width: "25ch",
                                  },
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                {e?.guardian_firstname ||
                                e?.guardian_name ||
                                e?.guardian_email ||
                                e?.guardian_mobile ? (
                                  <>
                                    <TextField
                                      disabled
                                      label="Tag Number"
                                      value={e?.tag_number}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}

                                {e?.guardian_firstname || e?.guardian_name ? (
                                  <>
                                    <TextField
                                      disabled
                                      label="Guardian Name"
                                      value={
                                        e?.guardian_firstname
                                          ? e.guardian_firstname +
                                            (e.guardian_lastname || "")
                                          : e.guardian_name
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}

                                {e?.guardian_email ? (
                                  <>
                                    <TextField
                                      disabled
                                      label="Email"
                                      value={e?.guardian_email}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}

                                {e?.guardian_mobile ? (
                                  <>
                                    <TextField
                                      disabled
                                      label="Phone"
                                      value={e?.code || "" + e?.guardian_mobile}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}
                                {e?.guardian_alternate_email ? (
                                  <>
                                    <TextField
                                      disabled
                                      value={e?.guardian_alternate_email}
                                      label="Alternate Email"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}

                                {e?.guardian_alternate_mobile ? (
                                  <>
                                    <TextField
                                      disabled
                                      value={e?.guardian_alternate_mobile}
                                      label="Alternate Phone"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "#666" },
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "#000000",
                                        },
                                      }}
                                    />
                                  </>
                                ) : null}
                              </Box>
                              {e?.guardian_firstname ||
                              e?.guardian_name ||
                              e?.guardian_email ||
                              e?.guardian_mobile ? (
                                <>
                                  <div style={{ margin: "1rem" }}>
                                    <Button
                                      onClick={() => {
                                        window.location.href =
                                          "/guardian-details/" + e?.tag_number;
                                      }}
                                      variant="outlined"
                                      startIcon={<ModeIcon />}
                                      sx={{
                                        "&:focus": {
                                          border:
                                            "1px solid rgba(25, 118, 210, 0.5)",
                                          color: "#1976d2",
                                          // Adjust the color and style as needed
                                        },
                                      }}
                                    >
                                      Edit Details
                                    </Button>
                                  </div>
                                </>
                              ) : null}
                            </Grid>
                          </>
                        );
                      } else {
                        return null;
                      }
                    })
                  ) : (
                    <Grid item xs={12}>
                      <p>No QR Tags are available</p>
                    </Grid>
                  )}
                </Collapse>
                <List>
                  <ListItemButton
                    onClick={() =>
                      setpesonalinf({
                        ...personelinf,
                        menu2: !personelinf?.menu2,
                      })
                    }
                  >
                    <ListItemText>
                      <h5>QR Pet Tag</h5>
                    </ListItemText>
                    {personelinf?.menu2 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </List>
                <Collapse in={personelinf?.menu2}>
                  <Grid container spacing={3}>
                    {/* {petinf.map((e) => {
                      if (
                        e?.parent_firstname ||
                        e?.parent_email ||
                        e?.parent_phone ||
                        e?.parent_altemail ||
                        e?.parent_altphone
                      ) {
                        return (
                          
                        );
                      }
                    })} */}

                    {petinf.some((e) => e != null) ? (
                      petinf.map((e, index) => {
                        if (
                          e &&
                          (e.parent_firstname ||
                            e.parent_email ||
                            e.parent_phone ||
                            e.parent_altemail ||
                            e.parent_altphone)
                        ) {
                          return (
                            <>
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      m: 1,
                                      width: "25ch",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    disabled
                                    label="Tag Number"
                                    value={e?.tag_number}
                                    InputLabelProps={{
                                      shrink: true,
                                      style: { color: "#666" },
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                      },
                                    }}
                                  />

                                  {e?.parent_firstname ? (
                                    <>
                                      <TextField
                                        disabled
                                        label="Guardian Name"
                                        value={
                                          e?.parent_firstname +
                                            " " +
                                            e?.parent_lastname || ""
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#666" },
                                        }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input.Mui-disabled":
                                            {
                                              WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                      />
                                    </>
                                  ) : null}

                                  {e?.parent_email ? (
                                    <>
                                      <TextField
                                        disabled
                                        label="Email"
                                        value={e?.parent_email}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#666" },
                                        }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input.Mui-disabled":
                                            {
                                              WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                      />
                                    </>
                                  ) : null}

                                  {e?.parent_phone ? (
                                    <>
                                      <TextField
                                        disabled
                                        label="Phone"
                                        value={e?.parent_phone}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#666" },
                                        }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input.Mui-disabled":
                                            {
                                              WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                      />
                                    </>
                                  ) : null}
                                  {e?.parent_altemail ? (
                                    <>
                                      <TextField
                                        disabled
                                        label="Alternate Email"
                                        value={e?.parent_altemail}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#666" },
                                        }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input.Mui-disabled":
                                            {
                                              WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                      />
                                    </>
                                  ) : null}

                                  {e?.parent_altemail ? (
                                    <>
                                      <TextField
                                        disabled
                                        label="Alternate Phone"
                                        value={e?.parent_altphone}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#666" },
                                        }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input.Mui-disabled":
                                            {
                                              WebkitTextFillColor: "#000000",
                                            },
                                        }}
                                      />
                                    </>
                                  ) : null}
                                </Box>
                                <div style={{ margin: "1rem" }}>
                                  <Button
                                    onClick={() => {
                                      window.location.href =
                                        "/pet-parent-details/" + e?.tag_number;
                                    }}
                                    variant="outlined"
                                    startIcon={<ModeIcon />}
                                    sx={{
                                      "&:focus": {
                                        border:
                                          "1px solid rgba(25, 118, 210, 0.5)",
                                        color: "#1976d2",
                                        // Adjust the color and style as needed
                                      },
                                    }}
                                  >
                                    Edit Details
                                  </Button>
                                </div>
                              </Grid>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })
                    ) : (
                      <Grid item xs={12}>
                        <p>No QR Tags are available</p>
                      </Grid>
                    )}
                  </Grid>
                </Collapse>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <Dialog open={detailsmodal} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Basic Information</DialogTitle>
        <DialogContent>
          <form>
            <div className="twodiv">
              <div className="namedivprofile">
                <TextField
                  required
                  label="First Name"
                  name="first_name"
                  onChange={handelchange}
                  value={editableuserdata?.first_name}
                  variant="outlined"
                />
              </div>
              <div className="namedivprofile">
                <TextField
                  required
                  label="Last Name"
                  name="last_name"
                  onChange={handelchange}
                  value={editableuserdata?.last_name}
                  variant="outlined"
                />
              </div>
            </div>
            <div style={{ margin: "1rem" }}>
              <TextField
                style={{ width: "100%" }}
                label="Address111"
                name="address"
                onChange={handelchange}
                value={editableuserdata?.address}
                multiline
                rows={4}
              />
            </div>
            <div style={{ margin: "1rem" }}>
              <MuiPhoneInput
                required
                label="Contact Number"
                defaultCountry="in"
                onChange={phonehandler}
                value={`+${editableuserdata?.phonecountrycode} ${editableuserdata?.phone}`}
                regions={[
                  "america",
                  "caribbean",
                  "asia",
                  "middle-east",
                  "oceania",
                  "europe",
                  "africa",
                ]}
                name="mobile"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      edge="end"
                      aria-label="personal-details-icon"
                    ></IconButton>
                  ),
                }}
              />
            </div>
            <div style={{ margin: "1rem" }}>
              <MuiPhoneInput
                label="Emergency Contact Number"
                onChange={Emergencyphonehandler}
                defaultCountry="in"
                value={`+${editableuserdata?.emergencyphonecountrycode} ${editableuserdata?.emergencyphone}`}
                regions={[
                  "america",
                  "caribbean",
                  "asia",
                  "middle-east",
                  "oceania",
                  "europe",
                  "africa",
                ]}
                name="guardian_mobile"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      edge="end"
                      aria-label="personal-details-icon"
                    ></IconButton>
                  ),
                }}
              />
            </div>

            <div style={{ margin: "1rem" }}>
              <Button type="submit" onClick={handeldetails}>
                Submit
              </Button>
              <Button onClick={() => setdetailsmodal(false)}>Cancel</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={emailmodal} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Email Address</DialogTitle>
        <DialogContent>
          <form>
            <div style={{ margin: "1rem" }}>
              <TextField
                required
                label="Email Address"
                name="email"
                value={editableuserdata?.email}
                onChange={handelchange}
                variant="outlined"
              />
            </div>

            <div style={{ margin: "1rem" }}>
              <TextField
                required
                name="confirmpassword"
                onChange={handelchange}
                label="Password"
                variant="outlined"
              />
            </div>
            <div style={{ margin: "1rem" }}>
              <Button onClick={() => setemailmodal(false)}>Cancel</Button>
              <Button type="submit" onClick={handelemailchange}>
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={otpmodal} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Verify Otp</DialogTitle>
        <DialogContent>
          <form onSubmit={handelotpverify}>
            <div style={{ margin: "1rem" }}>
              <TextField
                required
                name="otp"
                value={otpvalue}
                onChange={(e) => setoptvalue(e.target.value)}
                label="otp"
                variant="outlined"
              />
            </div>
            <div style={{ margin: "1rem" }}>
              <Button
                onClick={() => {
                  setoptmodal(false);
                  setoptvalue("");
                  setdeletedata({
                    email: "",
                    confirmpassword: "",
                    reason: "",
                  });
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Submit</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteotp} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Verify Otp</DialogTitle>
        <DialogContent>
          <form onSubmit={verifydeleteOtp}>
            <div style={{ margin: "1rem" }}>
              <TextField
                required
                name="otp"
                value={otpvalue}
                onChange={(e) => setoptvalue(e.target.value)}
                label="otp"
                variant="outlined"
              />
            </div>
            <div style={{ margin: "1rem" }}>
              <Button
                onClick={() => {
                  setdeleteotp(false);
                  setoptvalue("");
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Submit</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={counter} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ marginLeft: "1rem" }}
            id="alert-dialog-description"
          >
            Are you sure you wanted to delete the account.All data will be
            losted permananently
          </DialogContentText>
          <div
            style={{
              margin: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CountdownCircleTimer
              size={100}
              strokeWidth={6}
              isPlaying
              duration={10}
              colors="#EF798A"
              onComplete={() => {
                // do your stuff here
                setdeleteproceed(true);
              }}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handeldeleterevoke}>Disagree</Button>
          {deleteproceed ? (
            <Button onClick={deleteaccountApi}>Agree</Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <Dialog open={deletemodal} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Delete Account</DialogTitle>

        <DialogContentText
          style={{ marginLeft: "1rem" }}
          id="alert-dialog-description"
        >
          Are you sure you wanted to delete the account.All data will be losted
          permananently
        </DialogContentText>

        <DialogActions>
          <Button onClick={() => setdeletemodal(false)}>Disagree</Button>
          <Button
            onClick={() => {
              setdeletemodal(false);
              setdeletepassword(true);
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deletepassword} fullWidth="sm" maxWidth="sm">
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "1rem",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <form onSubmit={sendotpfordelete}>
                <div style={{ margin: "1rem" }}>
                  <TextField
                    required
                    label="Email Address"
                    name="email"
                    value={deletedata?.email}
                    onChange={handeldeletechange}
                    variant="outlined"
                  />
                </div>

                <div style={{ margin: "1rem" }}>
                  <TextField
                    required
                    name="confirmpassword"
                    onChange={handeldeletechange}
                    label="Password"
                    variant="outlined"
                  />
                </div>

                <div style={{ margin: "1rem" }}>
                  <TextField
                    required
                    label="Why are you deleting the account"
                    name="reason"
                    onChange={handeldeletechange}
                    value={editableuserdata?.reason}
                    multiline
                    rows={4}
                  />
                </div>
                <div style={{ margin: "1rem" }}>
                  <Button
                    onClick={() => {
                      setdeletepassword(false);
                      setdeletedata({
                        email: "",
                        confirmpassword: "",
                        reason: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Submit</Button>
                </div>
              </form>
            </>
          )}
        </DialogContent>
      </Dialog>
      {/* <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={tagNotFound}
        onClose={handleNotFound}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="small__popup">
          <h3>Tag number not found</h3>

          <button className="small_pop_btn" onClick={handleNotFound}>
            OK
          </button>
        </DialogContent>
      </Dialog>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={alreadyActivated}
        onClose={handleActivated}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="small__popup">
          <h3>Tag is already activated</h3>

          <button
            className="otp-buttons small_pop_btn"
            onClick={handleActivated}
          >
            OK
          </button>
        </DialogContent>
      </Dialog> */} 
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={sucess}
        autoHideDuration={6000}
        onClose={() => setsucess(false)}
      >
        <Alert
          style={{ backgroundColor: "#90EE90" }}
          onClose={() => setsucess(false)}
          severity="success"
          sx={{
            height: '100px', // Increase height of the Alert
            // width: '100%', // Ensures Alert takes full width of the Snackbar
            fontSize: '1.2rem', // Optionally adjust font size for better readability
          }}
        >
          {alertmsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error}
        autoHideDuration={6000}
        onClose={() => seterror(false)}
      >
        <Alert
          style={{ backgroundColor: "#FFCCCB" }}
          onClose={() => seterror(false)}
          severity="error"
          sx={{
            height: '100px', // Increase height of the Alert
            // width: '100%', // Ensures Alert takes full width of the Snackbar
            fontSize: '1.2rem', // Optionally adjust font size for better readability
          }}
        >
          {alertmsg}
        </Alert>
      </Snackbar>
      {tagNotFound && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setTagNotFound(false)}
            anchorOrigin={{ vertical: "top", horizontal:  "center", }}
            sx={{
              marginTop: "580px", // Optionally add margin-top if needed
              width:"100%"
            }}
          >
          <Alert severity="warning" onClose={() => setTagNotFound(false)} sx={{
            height: '50px', // Increase height of the Alert
            // width: '100%', // Ensures Alert takes full width of the Snackbar
            fontSize: '1.2rem', // Optionally adjust font size for better readability
          }}>
              Tag not found!
            </Alert>
          </Snackbar>
        )}

        {alreadyActivated && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setAlreadyActivated(false)}
            anchorOrigin={{
                      vertical: 'top', // Positioning vertically at the top
                      horizontal:  "center", // Positioning horizontally at the right
                    }}
                    sx={{
                      marginTop: '580px', // Optionally add margin-top if needed
                      width:"100%"
                    }}
          >
            <Alert severity="info" onClose={() => setAlreadyActivated(false)} sx={{
                height: '50px', // Increase height of the Alert
                // width: '100%', // Ensures Alert takes full width of the Snackbar
                fontSize: '1.2rem', // Optionally adjust font size for better readability
              }}>
              Tag is already activated.
            </Alert>
          </Snackbar>
        )} 
              {renderQRModal()}
       {loader && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Newpro;
