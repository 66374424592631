import React, { useState, useEffect } from "react";
import Navbar from "../header/header.component";
// import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import {
  Typography,
  Modal,
  Box,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Divider,
  MenuItem,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { ListItem, ListItemIcon } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import InfoIcon from "@mui/icons-material/Info";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Consentform() {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    if (ca) {
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) === " ") c = c.substring(1, c.length);

        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  }
  const token = readCookie("token");
  const [state, setState] = useState({
    consentrequired: true,
    consentpersonalize: false,
  });
  // const [concnet, setConcent] = useState(false);
  // const [usedChecked, setUsedChecked] = useState(false);
  const [open, setOpen] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useHistory();
  const { consentrequired, consentpersonalize } = state;

  const handelConsent = async () => {
    try {
      // console.log(consentrequired, consentpersonalize, "getting or not");
      if (consentrequired !== undefined && consentpersonalize !== undefined) {
        // Ensure both flags are defined
        const { data } = await axios.post(
          "/api/manageconcent",
          { consentrequired, consentpersonalize },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        if (data?.status) {
          navigate.push("/");
        } else {
          navigate.push("/login");
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    event.stopPropagation();
    const { name, checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  // const handleUsedCheckboxChange = (event) => {
  //   console.log(event, "eventtt");
  //   setUsedChecked(event.target.checked);
  //   console.log(event.target.checked, "usedChecked");
  // };
  const handleRedirect = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const linkStyle = {
    color: "blue",
    textDecoration: "underline",
    fontFamily: "Prompt, sans-serif",
    cursor: "pointer",
  };

  const dashboardLinkStyle = {
    color: "black",
    fontFamily: "Prompt, sans-serif",
    cursor: "pointer",
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          sx: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
          },
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 400,
            bgcolor: "white",
            // border: "1px solid #6563635e",
            borderRadius: 5,
            boxShadow: 24,
            p: 2,
          }}
        >
          <div style={{ textAlign: "left" }}>
            <div className="left-logo">
              <img
                style={{ width: "5em" }}
                src="https://storage.googleapis.com/pettag/qrtagdev/assets/tag8logoblack.png"
                alt="logo"
              ></img>
            </div>
          </div>
          <Typography
            variant="h4"
            align="center"
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.5rem" },
              fontWeight: "550",
              fontFamily: "prompt, sans-serif",
              // color: "rgba(0, 0, 0, 0.87)",
              margin: "1rem",
            }}
          >
            User Consent
          </Typography>
          <div>
            <div style={{ margin: "0rem 0rem -1rem 0rem" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{
                    flexDirection: "row-reverse",
                    minHeight: "30px",
                    paddingTop: "0.5rem",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consentrequired}
                        onClick={handleChange}
                        disabled
                        sx={{
                          color: "default",
                        }}
                        name="consentrequired"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "1rem", sm: "1.25rem" },
                          fontFamily: "prompt, sans-serif",
                        }}
                      >
                        Required
                      </Typography>
                    }
                    style={{ marginRight: "8px" }}
                  />
                </AccordionSummary>
                <AccordionDetails
                  sx={{ margin: "-1rem -2rem 0rem -3rem", padding: "0px" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon
                        style={{
                          fontSize: "small",
                          margin: "0rem -1rem 5rem 2rem",
                        }}
                      />
                    </ListItemIcon>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "0.750rem", sm: "1rem" },
                        fontFamily: "prompt",
                        letterSpacing: "0",
                        lineHeight: "1.50",
                        textWrap: "balance",
                      }}
                    >
                      We collected your name, email, phone number, and location
                      to notify you when the tag is scanned. These details are
                      publicly visible to anyone who scans the tag. Your email
                      and phone number are displayed to the finder to facilitate
                      contact if your tag is lost or found.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon
                        style={{
                          fontSize: "small",
                          margin: "0rem 0rem 2rem 2rem",
                        }}
                      />
                    </ListItemIcon>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "0.750rem", sm: "1rem" },
                        fontFamily: "prompt",
                        letterSpacing: "0",
                        lineHeight: "1.50",
                        textWrap: "balance",
                      }}
                    >
                      These cookies are necessary for the site to function
                      properly, including capabilities like logging in and
                      adding items to the cart.
                    </Typography>
                  </ListItem>
                </AccordionDetails>
              </Accordion>
            </div>

            <div style={{ margin: "0rem 0rem" }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{
                    flexDirection: "row-reverse",
                    minHeight: "30px",
                    paddingTop: "0.5rem",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={consentpersonalize}
                        onClick={handleChange}
                        name="consentpersonalize"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontSize: { xs: "1rem", sm: "1.25rem" },
                          fontFamily: "prompt, sans-serif",
                        }}
                      >
                        Marketing & Promotional
                      </Typography>
                    }
                    style={{ marginRight: "8px" }}
                  />
                </AccordionSummary>
                <AccordionDetails
                  sx={{ margin: "-1rem -2rem 0rem -3rem", padding: "0px" }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon
                        style={{
                          fontSize: "small",
                          margin: "-2rem 0rem 0rem 2rem",
                        }}
                      />
                    </ListItemIcon>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "0.750rem", sm: "1rem" },
                        fontFamily: "prompt",
                        letterSpacing: "0",
                        lineHeight: "1.50",
                        textWrap: "balance",
                      }}
                    >
                      These settings optimize marketing communications and ads
                      based on your browsing behavior, helping us tailor content
                      to your interests and measure campaign effectiveness.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon
                        style={{
                          fontSize: "small",
                          margin: "-2rem 0rem 0rem 2rem",
                        }}
                      />
                    </ListItemIcon>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        fontSize: { xs: "0.750rem", sm: "1rem" },
                        fontFamily: "prompt",
                        letterSpacing: "0",
                        lineHeight: "1.50",
                        textWrap: "balance",
                        margin: "0rem 0rem 2.8rem 0rem",
                      }}
                    >
                      These cookies store details about your actions to
                      personalize your next visit to the website.
                    </Typography>
                  </ListItem>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <Grid item xs={12}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  onChange={() => setconcent(!concnet)}
                  checked={concnet}
                  name="saveAddress"
                  value="yes"
                  style={{
                    fontFamily: "prompt, sans-serif",
                    margin: "0rem 0rem 0rem 1rem",
                  }}
                />
              }
              label="I Accept"
            /> */}
          </Grid>
          <Divider variant="middle" />
          <Typography
            variant="body2"
            align="center"
            sx={{
              color: "rgba(0, 0, 0, 0.54)",
              marginTop: { xs: "0.7rem", sm: "0.5rem", md: "0.3rem" },
              fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9rem" },
              fontFamily: "Prompt, sans-serif",
              textAlign: "left",
            }}
          >
            Accepting these permissions means that you allow this app to use
            your data as specified in their{" "}
            <span
              style={linkStyle}
              onClick={() =>
                handleRedirect("https://home.tag8.in/policies/terms-of-service")
              }
            >
              terms and conditions
            </span>{" "}
            and{" "}
            <span
              style={linkStyle}
              onClick={() =>
                handleRedirect("https://home.tag8.in/policies/privacy-policy")
              }
            >
              privacy statement
            </span>
            . You can change these permissions at https://dashboard.tag8.in/
            <span
              style={dashboardLinkStyle}
              onClick={() => handleRedirect("https://dashboard.tag8.in/")}
            >
              . {""}Show Details
            </span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "prompt, sans-serif",
            }}
          >
            {/* <Button
              onClick={() => {
               handleClose();
              }}
              sx={{
                mt: 3,
                ml: 1,
                bgcolor: "whitesmoke",
                color: "black",
                "&:hover": {
                  bgcolor: "#e0e0e0",
                },
                fontFamily: "prompt,sans-serif",
                fontSize: "small",
              }}
              variant="contained"
              color="error"
            >
              Cancel
            </Button> */}
            <Button
              variant="contained"
              color="success"
              onClick={handelConsent}
              sx={{
                mt: 3,
                ml: 1,
                bgcolor: "#fcbc1b",
                "&:hover": {
                  bgcolor: "#e0e0e0",
                },
                color: "black",
                fontFamily: "prompt,sans-serif",
                fontSize: "small",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default Consentform;
